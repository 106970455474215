<template>
    <div class="page-content">
        <div class="container-fluid">
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Quản lý tên ngoại Facebook</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript: void(0);">Admin Traodoicheo</a>
                                </li>
                                <li class="breadcrumb-item active">Quản lý tên ngoại Facebook</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end page title -->

            <div class="row">
                <div class="col-12">
                    <div class="card py-4">
                        <div class="container-fluid">
                            <div class="row">
                                <form @submit.prevent="getBlackList" class="col-md-4 mb-4 mb-md-0">
                                    <div class="form-group">
                                        <label for="">Tìm theo Fb Id</label>
                                        <input type="text" class="form-control" placeholder="nhập fb id" v-model="fb_id" />
                                    </div>
                                </form>
                                <!--
                                <form @submit.prevent="getBlackList" class="col-md-4 mb-4 mb-md-0">
                                    <div class="form-group">
                                        <label for="">Tìm theo User Id</label>
                                        <input type="text" class="form-control" placeholder="nhập user id"
                                            v-model="user_id" />
                                    </div>
                                </form>
                                -->
                                <form class="col-md-4 mb-4 mb-md-0">
                                    <div class="form-group">
                                        <label for="">Limit</label>
                                        <select class="form-select" v-model="limit" @change="getBlackList">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <loading-vue v-if="loading" />
            <div class="row">


                <div class="col-md-12">

                </div>
                <datatable :stt="true" :title="'Danh sách tên ngoại Facebook'" :columns="columns" :rows="manipulatedList"
                    :defaultPerPage="10">
                    <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
                    <td slot="tbody-tr-o" class="text-center" slot-scope="props">
                        <div class="d-flex justify-content-between">
                            <input class="form-check-input" type="checkbox" v-model="props.row.isSelected"
                                @change="checkBox(props.row)">

                            <button style="width: 100px;" v-if="props.row.disallow"
                                class="btn btn-danger font-10 py-1 btn-xs mr-3" @click="disallow(props.row.fb_id, false)">
                                allow
                            </button>

                            <button style="width: 100px;" v-else class="btn btn-danger font-10 py-1 btn-xs"
                                @click="disallow(props.row.fb_id, true)">
                                Disallow
                            </button>
                        </div>
                    </td>

                    <div slot="buttons" style="width: 315px; margin-left: 10px;" class="d-flex justify-content-between">
                        <button style="width: 150px;" class="pl-3  btn btn-danger font-10 py-1 btn-xs mr-3"
                            @click="disallowall(true)">
                            Disallow nhiều
                        </button>

                        <button style="width: 150px;" class="btn btn-danger font-10 py-1 btn-xs"
                            @click="disallowall(false)">
                            Allow nhiều
                        </button>
                    </div>


                </datatable>
            </div>
        </div>
    </div>
</template>
  
<script>
/* eslint-disable no-undef */
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, catchError } from "@/helpers/index";
import invalidList from "@/api/invalid-name";

export default {
    name: "BlackListFacebook",
    components: {
        Datatable,
        LoadingVue,
    },
    data() {
        return {
            username: null,
            fb_id: null,
            user_id: null,
            limit: 50,
            list: [],
            loading: false,
            columns: [
                { label: "Id", field: "id" },
                { label: "User Id", field: "user_id" },
                { label: "Fb Id", field: "fb_id", fb_link: true },
                { label: "Fb Name", field: "fb_name", fb_link: true },
                { label: "Kết quả", field: "result" },
                { label: "Ngày tạo", field: "created_at", timeago: true },
                { label: "Ngày cập nhật", field: "updated_at", timeago: true },
            ],
            listCheck: []
        };
    },
    computed: {
        manipulatedList() {
            return this.list;
        },
    },
    created() {
        this.getBlackList()
    },
    methods: {
        async getBlackList() {
            const params = {
                limit: this.limit,
                fb_id: this.fb_id
            };
            const queries = buildParamQuery(params);
            this.loading = true;
            let response = await invalidList.list(queries)


            this.loading = false;
            if (response) {
                if (response.success) {
                    response.data = response.data.map(row => { return { ...row, isSelected: false } })
                    this.list = response.data || [];
                } else {
                    this.$toastr.error(catchError(response));
                }
            } else {
                this.$toastr.error("Tải danh sách không thành công !");
            }
        },

        async disallow(fb_id, disallowFbid) {
            const postData = {
                fb_id: fb_id,
                disallow: disallowFbid,
            };

            this.loading = true;
            const response = await invalidList.disallow(postData)
            this.loading = false;

            if (response) {
                if (response.success) {
                    const success = response.data.success || []
                    this.list = this.list.map(fb => {
                        if (success.includes(fb.fb_id)) {
                            return {
                                ...fb,
                                disallow: disallowFbid,
                                isSelected: false
                            }
                        } else {
                            return { ...fb, isSelected: false }
                        }
                    })

                    this.$toastr.success('Cập nhật thành công');
                } else {
                    this.$toastr.error(catchError(response));
                }
            } else {
                this.$toastr.error("Tải danh sách tên facebook không hợp lệ không thành công !");
            }
        },
        checkBox(row) {
            const { isSelected, fb_id } = row

            if (isSelected) {
                this.listCheck.push(fb_id)
            } else {
                this.listCheck = this.listCheck.filter(value => value != fb_id)
            }
        },
        async disallowall(disallowFbid) {
            const fb_id = this.listCheck.toString()

            await this.disallow(fb_id, disallowFbid)
        }
    },
};
</script>
  
<style lang="scss" scoped></style>
  